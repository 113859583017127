<template>
  <div class="d-flex justify-content-center">
    <b-card class="col-lg-9">
      <b-row class="containerCreateAdmin">
        <b-col class="container-form">
          <div class="cols-12 col-lg-12 d-flex flex-column">
            <validation-observer ref="accountRules" tag="form">
              <div class="">
                <b-col lg="12">
                  <b-form-group label="Full Name">
                    <validation-provider #default="{ errors }" rules="required" name="name">
                      <b-form-input v-model="itemAdmin.name" :state="errors.length > 0 ? false : null" :maxlength="100"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group label="Email">
                    <validation-provider #default="{ errors }" rules="required|email" name="email" >
                      <b-form-input v-model.trim="itemAdmin.email" :state="errors.length > 0 ? false : null" :maxlength="100"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group label="Role">
                    <validation-provider #default="{ errors }" rules="required" name="role">
                      <b-form-select @change="onChangeRole($event)" v-model="itemAdmin.role" :options="optionsRoles"
                        label="Role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col lg="12 mb-2">
                  <p>This User contain follwing Permissionsss</p>
                  <div v-if="itemAdmin.id === ''">
                    <span v-for="(permission, index) in permissionsByRole" :key="index">
                      <small class="permissionsByRole">{{
                        permission.name
                      }}</small>
                    </span>
                  </div>
                  <div v-if="permissionByUser && itemAdmin.id !== ''">
                    <span v-for="(permission, index) in permissionByUser" :key="index">
                      <small class="permissionsByRole">{{
                        permission.name
                      }}</small>
                    </span>
                  </div>
                </b-col>

                <b-row class="m-0" lg="12" v-if="itemAdmin.id === ''">
                  <b-col cols="6">
                    <b-form-group label="Password">
                      <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required">
                        <b-form-input id="txtPassword" autocomplete="off" ref="password" type="password"
                          v-model="itemAdmin.password" :state="errors.length > 0 ? false : null" :maxlength="50"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6">
                    <b-form-group label="Confirmation">
                      <validation-provider #default="{ errors }" rules="required|confirmed:Password"
                        name="Password Confirm">
                        <b-form-input id="txtPasswordConfirm" autocomplete="off" type="password"
                          v-model="passwordConfirmation" :state="errors.length > 0 ? false : null" :maxlength="50" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" v-if="itemAdmin.id === ''">
                    <b-form-group label="" style="text-align: right;">
                      <input @click="tooglePassword()" type="checkbox">Show Password
                    </b-form-group>

                  </b-col>
                </b-row>
              </div>

              <b-col lg="12 mb-2">
                <h4>Additional Permissions</h4>
                <table>
                  <tr>
                    <td style="padding-right: 15px"></td>
                    <td style="padding-right: 15px">Defatult</td>
                    <td style="padding-right: 15px">Allow access</td>
                    <td style="padding-right: 15px">Deny access</td>
                  </tr>
                  <tr v-for="(editablePermissions, index) in editablePermissions" :key="index">
                    <td>
                      <small style="font-weight: bolder; margin-left: 10px">{{
                        editablePermissions.name
                      }}</small>
                    </td>
                    <td style="padding-right: 15px">
                      <input style="margin-left: 10px; margin-right: 5px" type="radio" :id="editablePermissions.slug + 0"
                        :name="editablePermissions.slug" value="default" :checked="hasPermisssion(editablePermissions.slug) == null ||
                          (hasPermisssion(editablePermissions.slug) == true && isDefaultPermission(editablePermissions) == true)

                          ? 'checked'
                          : ''
                          " />
                    </td>
                    <td>
                      <input style="margin-left: 10px; margin-right: 5px" type="radio" :id="editablePermissions.slug + 1"
                        :name="editablePermissions.slug" value="allow" :checked="hasPermisssion(editablePermissions.slug) == true &&
                          isDefaultPermission(editablePermissions) == false
                          ? 'checked'
                          : ''
                          " />
                    </td>
                    <td>
                      <input style="margin-left: 10px; margin-right: 5px" type="radio" :id="editablePermissions.slug + 2"
                        :name="editablePermissions.slug" value="deny" :checked="hasPermisssion(editablePermissions.slug) == false
                          ? 'checked'
                          : ''
                          " />
                    </td>
                  </tr>
                </table>
              </b-col>

              <div class="pl-1">
                <b-button variant="primary" @click="sendForm">
                  <span class="d-none d-sm-inline">Save</span>
                  <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                </b-button>
                <b-button style="margin-left: 20px" variant="danger" @click="$router.go(-1)">
                  <span class="d-none d-sm-inline">Cancel</span>
                  <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                </b-button>
              </div>
            </validation-observer>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCard,
  BCardTitle,
  BCardText,
  BFormSelect,
  BFormDatepicker,
  BFormRadio,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Cleave from "vue-cleave-component";
import { optionsRoles } from "@core/utils/optionsRoles";
import { getMessageError } from "@core/utils/utils";
import { forEach } from "postcss-rtl/lib/affected-props";

export default {
  name: "FormAdmin",
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
    BFormSelect,
    BFormDatepicker,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      permissionsByRole: [],
      permissionByUser: [],
      editablePermissions: [],
      additionalPermisions: [],
      itemAdmin: {
        id: "",
        name: "",
        email: "",
        role: 1,
        password: "",
        additionalPermisions: [],
        permissions: [],
        permissions_by_user: [],
      },
      passwordConfirmation: "",
      optionsRoles: [
        {
          text: "Super Admin",
          value: 1,
        },
        {
          text: "Admin",
          value: 2,
        },
        {
          text: "Dispatch",
          value: 4,
        },
        {
          text: "Care Coordinator",
          value: 5,
        },
        {
          text: "Provider Relations",
          value: 6,
        },
        {
          text: "Human Resource",
          value: 7,
        },
        {
          text: "Billing",
          value: 8,
        },
      ],
      roleWasChange: false,
    };
  },
  methods: {
    onChangeRole($e) {
      this.roleWasChange = true;
      this.setDefaultPermissionRadioInput();
      this.getPermissionsAgainstRole($e);
    },
    async getPermissionsAgainstRole(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`auth/admin/role/${id}/permission`)
        .then((response) => {
          this.permissionsByRole = response.data.data.permission;

          if (this.roleWasChange == true) {
            this.permissionByUser = [];
            this.permissionByUser = this.permissionsByRole
          }
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    sendForm() {
      this.validateForm();
    },
    saveData() {
      this.setListEditablePermissionsValues();
      this.itemAdmin.additionalPermisions = this.additionalPermisions;
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post("auth/admin/register", this.itemAdmin)
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.clearForm();
        })
        .catch((error) => {
          let message =
            error.response.data.data !== undefined
              ? error.response.data.data
              : error.response.data.message;
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    updateData() {
      this.itemAdmin.permissions = this.permissionByUser;
      this.setListEditablePermissionsValues();
      this.itemAdmin.additionalPermisions = this.additionalPermisions;
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post("auth/admin/update", this.itemAdmin)
        .then((response) => {
          this.$swal({
            title: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          let message =
            error.response.data.data !== undefined
              ? error.response.data.data
              : error.response.data.message;
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    clearForm() {
      this.itemAdmin.name = "";
      this.itemAdmin.email = "";
      this.itemAdmin.role = "";
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            if (this.itemAdmin.id > 0) {
              this.updateData();
            } else {
              this.saveData();
            }
          } else {
            reject();
          }
        });
      });
    },
    async getEditablePermissions() {
      await this.$http
        .get(`auth/admin/getAllPermission`)
        .then((response) => {
          this.editablePermissions = response.data.data;
          this.editablePermissions = this.editablePermissions.filter(
            (x) => x.isEditable == 1
          );
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    setListEditablePermissionsValues() {
      this.additionalPermisions = [];
      for (let element of this.editablePermissions) {
        let actionSeleted = this.getRadioActionSeleted(element.slug);
        let data = {
          id: element.id,
          slug: element.slug,
          action: actionSeleted,
        };
        this.additionalPermisions.push(data);
      }
    },
    getRadioActionSeleted(inputName) {
      var radios = document.getElementsByName(inputName);
      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          // do whatever you want with the checked radio
          return radios[i].value;
          // only one radio can be logically checked, don't check the rest
          break;
        }
      }
    },
    hasPermisssion(slug) {
      let res = false;
      //if user has permissions and role was not change
      if (this.permissionByUser.length > 0 && this.roleWasChange == false) {
        let item = this.permissionByUser.find((x) => x.slug === slug);
        if (item) {
          res = true;
        }
      } else {
        res = null;
      }

      return res;
    },
    setDefaultPermissionRadioInput() {
      for (let element of this.editablePermissions) {
        let id = element.slug + 0;
        document.getElementById(id).checked = true;
      }
    },
    isDefaultPermission(element) {
      let res = false;
      res = this.permissionsByRole.some((x) => x.id == element.id);
      return res;
    },
    async getInfo() {
      let data = this.$route.params.item;

      if (data) {
        this.itemAdmin = data;
        this.itemAdmin.role = data.role.id;
        let afd=this.itemAdmin.permissions_by_user;
        this.permissionByUser = this.itemAdmin.permissions_by_user.map(
          (x) => x.permission
        );
      }
      await this.getEditablePermissions();
      await this.getPermissionsAgainstRole(this.itemAdmin.role);
    },
    tooglePassword() {
        var p1 = document.getElementById("txtPassword");
        var p2 = document.getElementById("txtPasswordConfirm");
        if (p1.type === "password") {
          p1.type = "text";
          p2.type = "text";
        } else {
          p1.type = "password";
          p2.type = "password";
        }      
    },
    formatEmail(){
      this.itemAdmin.email= this.itemAdmin.email.trim();
    }
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.containerCreateAdmin {
  .imgAdmin {
    max-height: 356px;
    border-radius: 14px;
  }

  .container-form {
    display: flex;
    align-items: center;
  }
}

@media screen and(min-width: 320px) and (max-width: 1024px) {
  .containerCreateAdmin {
    flex-direction: column;
    gap: 10px;

    .imgAdmin {
      width: 100%;
    }
  }
}

.permissionsByRole {
  background: #332b7b;
  color: white;
  padding: 2px;
  border-radius: 5px;
  margin-right: 10px;
}
</style>
